import React, { createContext, ReactNode, useReducer } from 'react';

type IinitialState = {
    refetch: boolean,
    head: [],
    body: [],
    selected: [],
    sortConfig: { key: string, direction: 'ASC' | 'DESC' },
    pageMeta: { totalPage: number, pageIndex: number, elementsPerPage: number },
    filters: {}
};

const initialState = {
    refetch: false,
    head: [],
    body: [],
    selected: [],
    sortConfig: { key: 'createdAt', direction: 'DESC' },
    pageMeta: { totalPage: 1, pageIndex: 0, elementsPerPage: 10 },
    filters: {}
};

const tableContext = createContext<{
    state: IinitialState;
    dispatch: React.Dispatch<any>;
}>({
    state: initialState as IinitialState,
    dispatch: () => null
});

const reducer = (state: any, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case 'INIT_TABLE': {
            return {
                ...state,
                head: payload.head,
                body: payload.body
            };
        }
        case 'HANDLE_SELECTED': {
            return {
                ...state,
                selected: payload
            };
        }
        case 'HANDLE_SORT': {
            return {
                ...state,
                sortConfig: payload
            };
        }
        case 'HANDLE_PAGE': {
            return {
                ...state,
                pageMeta: payload
            };
        }
        case 'HANDLE_REFETCH': {
            return {
                ...state,
                refetch: !state.refetch
            };
        }
        case 'HANDLE_FILTER': {
            return {
                ...state,
                filters: payload
            };
        }
        default: return state;
    }
};

const TableProvider = ({ children }: { children?: ReactNode }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return <tableContext.Provider value={{ state, dispatch }}>{children}</tableContext.Provider>;
};

export { tableContext, TableProvider };
