import { useContext } from 'react';
import { SweetAlertOptionalProps, SweetAlertProps } from 'react-bootstrap-sweetalert/dist/types';
import { randomKeyGen } from '../../../utils/utils';
import { ActionKind, CustomAlertProps, modalContext } from '../context/modalContext';

/*
 Hook using react context "modalContext" to manipulate modal data
 It keeps alerts in the state, and remove them when the onConfirm or onCancel callbacks are invoked.
*/

type ReturnType = {
    readonly alerts: (SweetAlertProps & CustomAlertProps)[];
    readonly visibleAlert: (SweetAlertProps & CustomAlertProps) | null;
    readonly showAlert: (alertProps: SweetAlertOptionalProps & CustomAlertProps) => void;
    readonly closeAlert: (id?: string | undefined) => void;
    readonly clearAlert: () => void;
};

const useModal = (): ReturnType => {
    const { state, dispatch } = useContext(modalContext);

    const addAlert = (alert: SweetAlertProps & CustomAlertProps) => {
        dispatch({ type: ActionKind.ADD_ALERT, payload: alert });
    };

    const removeAlert = (id: string) => {
        dispatch({ type: ActionKind.REMOVE_ALERT, payload: id });
    };

    const clearAlert = () => {
        dispatch({ type: ActionKind.CLEAR_ALERT });
    };

    const closeAlert = (id?: string) => {
        if (id) removeAlert(id);
    };

    const showAlert = (alertProps: SweetAlertOptionalProps & CustomAlertProps) => {
        let alertCopy = { ...alertProps };
        if (!alertProps.id) {
            alertCopy = { ...alertCopy, id: randomKeyGen() };
        }
        if (!alertProps.title) {
            alertCopy = { ...alertCopy, title: '' };
        }

        // auto-close on confirm
        if (alertProps.onConfirm) {
            const { onConfirm } = alertProps;
            alertCopy = {
                ...alertCopy,
                onConfirm: (...args: []) => {
                    onConfirm(...args);
                    closeAlert(alertCopy.id);
                }
            };
        } else {
            alertCopy = { ...alertCopy, onConfirm: () => closeAlert(alertCopy.id) };
        }

        // auto-close on cancel
        if (alertProps.onCancel) {
            const { onCancel } = alertProps;
            alertCopy = {
                ...alertCopy,
                onCancel: (...args: []) => {
                    onCancel(...args);
                    closeAlert(alertCopy.id);
                }
            };
        } else {
            alertCopy = { ...alertCopy, onCancel: () => closeAlert(alertCopy.id) };
        }
        addAlert(alertCopy as SweetAlertProps);
    };

    return {
        alerts: state.alerts,
        visibleAlert: state.alerts.length > 0 ? state.alerts[0] : null,
        showAlert,
        closeAlert,
        clearAlert
    };
};

export default useModal;
