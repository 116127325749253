import { ReactComponent as IconItem } from '../../../assets/images/icons/menu/icon-item.svg';
import { ReactComponent as IconUser } from '../../../assets/images/icons/menu/icon-user.svg';
import { ReactComponent as IconDashboard } from '../../../assets/images/icons/menu/icon-dashboard.svg';
import { ReactComponent as IconBasic } from '../../../assets/images/icons/menu/icon-basic.svg';

type Menu = {
    label: string;
    url: string;
    class: string;
    active: string;
    icon: React.ReactNode;
};

export const menu: Menu[] = [
    {
        label: 'menu.dashboard',
        url: '/administration/dashboard/',
        class: 'menu-item',
        active: 'dashboard',
        icon: <IconDashboard />
    },
    {
        label: 'menu.admin-management',
        url: '/administration/administration-management/',
        class: 'menu-item',
        active: 'admin-management',
        icon: <IconUser />
    },
    {
        label: 'menu.user-management',
        url: '/administration/user-management/',
        class: 'menu-item',
        active: 'user-management',
        icon: <IconUser />
    },
    {
        label: 'menu.item-management',
        url: '/administration/item-management/',
        class: 'menu-item',
        active: 'item-management',
        icon: <IconItem />
    },
    {
        label: 'menu.basic-management',
        url: '/administration/basic-management/',
        class: 'menu-item',
        active: 'basic-management',
        icon: <IconBasic />
    }
];
