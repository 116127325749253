import React, { Suspense } from 'react';
import { AuthenticationProvider } from './components/authentication/context/authenticationContext';
import Compose from './routing/compose';
import Container from './routing/routes';

function App() {
	return (
		<Compose providers={[AuthenticationProvider]}>
			<Suspense fallback={<></>}>
				<main>
					<Container />
				</main>
			</Suspense>
		</Compose>
	);
};

export default App;
