import { useContext } from "react";
import { useTranslation } from 'react-i18next';
import { titleContext } from "./context/titleContext";
import { useNavigate } from "react-router-dom";

// Icons
import { ReactComponent as IconBack } from '../../assets/images/icons/icon-back.svg';

const Title = () => {
    
    const { t } = useTranslation();
    const { back, title } = useContext(titleContext);

    const navigate = useNavigate();

    const handleHistoryBack = () => {
        navigate(back as string);
    };

    return (
        <div className="header-title">
            {
                back &&
                <button type="button" className="btn btn-back" onClick={handleHistoryBack}>
                    <IconBack fill="#151515" />
                </button>
            }
            <h1>{t(title)}</h1>
        </div>
    );
};

export default Title;
