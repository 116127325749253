import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authenticationContext } from '../authentication/context/authenticationContext';
import Title from '../title/title';
import Cookies from 'js-cookie';

// Style
import './header.scss';

// Icons
import { ReactComponent as IconLogout } from '../../assets/images/icons/icon-logout.svg';

const Header: React.FC = () => {
    const [name, setName] = useState<string | null>(null);
    const navigate = useNavigate();
    const { setIsLogged } = useContext(authenticationContext);
    
    const cookie = Cookies.get('Auth');
    useEffect(() => {

        if(cookie) {
            const { firstname, lastname } = JSON.parse(cookie);
            // Set 1st letter from Firstname and Lastname, don't judge me plz
            setName(firstname.toUpperCase().substring(0, 1) + lastname.toUpperCase().substring(0, 1))
        }

    }, [cookie]);
    
    // Disconnect and navigate to `/login`
    const handleLogout = () => {
        Cookies.remove('Auth');
        setIsLogged(false);
        navigate('/');
    };

    return (
        <header>
            <Title />

            <div className="header-utils">
                <div className="btn-group justify-content-end">

                    <div className="btn btn-rounded fake-btn">
                        {name}
                    </div>

                    <button type="button" className="btn btn-rounded btn-logout" onClick={handleLogout}>
                        <IconLogout fill="#151515" />
                    </button>
                </div>
            </div>
        </header>
    );
};

export default Header;
