import React from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import PrivateRoute from './privateRoute';
import { TitleProvider } from '../components/title/context/titleContext';
import { ModalProvider } from '../components/modal/context/modalContext';
import { TableProvider } from '../components/table/context/tableContext';

// Layout
import AdminLayout from '../components/layouts/admin-layout';
import Compose from './compose';

const Login = import('../pages/login/Login');
const ForgetPassword = import('../pages/forget-password/forgetPassword');
const NewPassword = import('../pages/new-password/newPassword');
// Dashboard
const Dashboard = import('../pages/administration/dashboard/dashboard');
// Admin management
const AdministratorManagement = import('../pages/administration/administrator-management/administratorManagement');
const AddAdmin = import('../pages/administration/administrator-management/add-admin/add-admin');
const ViewAdmin = import('../pages/administration/administrator-management/view-admin/view-admin');
const EditAdmin = import('../pages/administration/administrator-management/edit-admin/edit-admin');
// User management
const UserList = import ('../pages/administration/user-management/user-list/user-list');
const WaitingList = import('../pages/administration/user-management/waiting-list/waiting-list');
const ViewUser = import('../pages/administration/user-management/view-user/view-user');
const ViewItem = import('../pages/administration/user-management/view-item/view-item');
// Item management
const ItemManagement = import('../pages/administration/item-management/item-management');
const ItemManagementItem = import('../pages/administration/item-management/view-item/view-item');
const ItemManagementDetails = import('../pages/administration/item-management/item-detail/item-detail');

const OutletTemplate = () => {
    return <Outlet />
};

function Container() {
    // Lazy loaded components
    const LazyLoadedLogin = React.lazy(() => Login);
    const LazyLoadedForgetPassword = React.lazy(() => ForgetPassword);
    const LazyLoadedNewPassword = React.lazy(() => NewPassword);
    const LazyLoadedDashboard = React.lazy(() => Dashboard);
    // Admin management
    const LazyLoadedAdministratorManagement = React.lazy(() => AdministratorManagement);
    const LazyLoadedAddAdmin = React.lazy(() => AddAdmin);
    const LazyLoadedViewAdmin = React.lazy(() => ViewAdmin);
    const LazyLoadedEditAdmin = React.lazy(() => EditAdmin);
    // User management
    const LazyLoadedUserList = React.lazy(() => UserList);
    const LazyLoadedWaitingList = React.lazy(() => WaitingList);
    const LazyLoadedViewUser = React.lazy(() => ViewUser);
    const LazyLoadedViewItem = React.lazy(() => ViewItem);
    // Item management
    const LazyLoadedItemManagement = React.lazy(() => ItemManagement);
    const LazyLoadedItemManagementItem = React.lazy(() => ItemManagementItem);
    const LazyLoadedItemManagementDetails = React.lazy(() => ItemManagementDetails);
    

    return (
        <Router>
            <Compose providers={[TitleProvider, ModalProvider]}>
                <Routes>

                    <Route path="/" element={<LazyLoadedLogin />} />
                    <Route path="/forget-password" element={<LazyLoadedForgetPassword />} />
                    <Route path="/new-password" element={<LazyLoadedNewPassword />} />

                    <Route path='administration' element={<PrivateRoute><AdminLayout /></PrivateRoute>}>
                        <Route path='dashboard' element={<LazyLoadedDashboard />} />

                        <Route path='administration-management/*' element={<OutletTemplate />}>
                            <Route index element={<TableProvider><LazyLoadedAdministratorManagement /></TableProvider>} />
                            <Route path='add' element={<LazyLoadedAddAdmin />} />
                            <Route path=':id/view' element={<LazyLoadedViewAdmin />} />
                            <Route path=':id/edit' element={<LazyLoadedEditAdmin />} />
                        </Route>

                        <Route path='user-management/*' element={<OutletTemplate />}>
                            <Route index element={<TableProvider><LazyLoadedUserList /></TableProvider>} />
                            <Route path="waiting-list" element={<TableProvider><LazyLoadedWaitingList /></TableProvider>} />
                            <Route path=":id/view" element={<LazyLoadedViewUser />} />
                            <Route path=":id/wardrobe/:item" element={<TableProvider><LazyLoadedViewItem /></TableProvider>} />
                        </Route>

                        <Route path='item-management/*' element={<OutletTemplate />}>
                            <Route index element={<TableProvider><LazyLoadedItemManagement /></TableProvider>} />
                            <Route path=":item" element={<TableProvider><LazyLoadedItemManagementItem /></TableProvider>} />
                            <Route path=":item/:id" element={<LazyLoadedItemManagementDetails />} />
                        </Route>
                    </Route>

                </Routes>
            </Compose>
        </Router>
    );
};

export default Container;
