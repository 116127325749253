import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import Modal from '../../components/modal/Modal';
import useModal from '../modal/hook/useModal';
import Header from '../header/header';
import Sidebar from '../sidebar/sidebar';

const AdminLayout = ({ children }: { children?: ReactNode }) => {
    const { visibleAlert } = useModal();

    return (
        <>
            <Header />
            <Sidebar />

            <div className="container">
                <div className="main-container">
                    <Outlet />
                </div>

            </div>

            {visibleAlert && <Modal visibleAlert={visibleAlert} />}
        </>
    );
};

export default AdminLayout;
