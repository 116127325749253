import React from 'react';

interface ComposeProps {
    providers: Array<React.JSXElementConstructor<React.PropsWithChildren<any>>>;
    children: React.ReactNode;
};

const Compose: React.FC<ComposeProps> = ({ providers = [], children, ...props }) => (
    <>
        {providers.reduceRight((acc, Comp) => {
            return <Comp {...props}>{acc}</Comp>;
        }, children)}
    </>
);

export default Compose;
