import { menu } from './data/menu';
import { useTranslation } from 'react-i18next';
import Copyright from '../copyright/copyright';

import CustomLink from '../customLink/CustomLink';

// Style
import './sidebar.scss';

// Icons
import { ReactComponent as Logo } from '../../assets/images/logo-vera.svg';


const Sidebar = () => {
    // Translation
    const { t } = useTranslation();

    return (
        <aside className="sidebar">
            <div className="sidebar-top">
                <Logo fill="#151515" />
            </div>

            <div className="sidebar-menu">
                <nav role="navigation">
                    <ul>
                        {
                            menu.map((item, index: number) => (
                                <li key={index}>
                                    <CustomLink to={item.url} className={item.class}>
                                        <span className="icon">{item.icon}</span>
                                        {t(item.label)}
                                    </CustomLink>
                                </li>
                            ))
                        }
                    </ul>
                </nav>
            </div>

            <Copyright />
        </aside>
    );
};

export default Sidebar;
