import React, { createContext, ReactNode, useState } from 'react';

type InitialState = {
    isLogged: boolean,
    setIsLogged: (isLogged: boolean) => void
};

const initialState: InitialState = {
    isLogged: false,
    setIsLogged: () => null
};

const authenticationContext = createContext(initialState);

const AuthenticationProvider = ({children} : {children?: ReactNode}) => {
    const [state, setState] = useState<InitialState>(initialState);

    const setIsLogged = (isLogged: boolean) => {
        setState({
            ...state,
            isLogged: isLogged
        });
    };

    return <authenticationContext.Provider value={{...state, setIsLogged}}>{children}</authenticationContext.Provider>
};

export {authenticationContext, AuthenticationProvider};
