import type { LinkProps } from 'react-router-dom';
import { useResolvedPath, useMatch, Link } from 'react-router-dom'

const CustomLink = ({children, to, ...props}: LinkProps) => {

    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: false });

    return (
        <Link to={to} {...props} className={`${ match && 'active' }`}>
            {children}
        </Link>
    );
};

export default CustomLink;
