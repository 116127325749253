import React, { createContext, useReducer, useMemo } from 'react';
import { SweetAlertProps } from 'react-bootstrap-sweetalert/dist/types';
import { ContextValue, ProviderProps } from '../../../types/types';

export type CustomAlertProps = {
    id?: string;
    isLoading?: boolean;
    content?: React.ReactNode | string;
    title?: React.ReactNode | string;
    onConfirm?: () => void;
    onCancel?: () => void;
};

export type State = {
    alerts: Array<SweetAlertProps & CustomAlertProps>;
};

const initialState: State = {
    alerts: []
};

export enum ActionKind {
    ADD_ALERT,
    REMOVE_ALERT,
    CLEAR_ALERT
}

type Action =
    | { type: ActionKind.ADD_ALERT; payload: SweetAlertProps & CustomAlertProps }
    | { type: ActionKind.REMOVE_ALERT; payload: string }
    | { type: ActionKind.CLEAR_ALERT };

const modalContext = createContext<ContextValue<State, Action>>({
    state: initialState,
    dispatch: (action) =>
        console.error('Dispatched action outside of an modalContext provider', action)
});

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionKind.ADD_ALERT:
            return {
                ...state,
                alerts: [...state.alerts, action.payload]
            };
        case ActionKind.REMOVE_ALERT:
            return {
                ...state,
                alerts: [...state.alerts].filter((a) => a.id !== action.payload)
            };
        case ActionKind.CLEAR_ALERT:
            return {
                ...state,
                alerts: []
            };
        default:
            return state;
    }
};

const ModalProvider: React.FC<ProviderProps> = ({ children }): JSX.Element => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

    return <modalContext.Provider value={contextValue}>{children}</modalContext.Provider>;
};

export { modalContext, ModalProvider };
