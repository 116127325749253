import React, { useEffect, useState } from 'react';
import { Navigate, RouteProps, useNavigate } from 'react-router-dom';
import AdminLayout from '../components/layouts/admin-layout';
import Cookies from 'js-cookie';

interface ComposeProps {
    children: React.ReactNode;
};

const PrivateRoute = ({ children }: RouteProps & ComposeProps) => {
    const [isConnected, setIsConnected] = useState<boolean>(true);
    const cookie: any = Cookies.get('Auth');
    const navigate = useNavigate();

    useEffect(() => {
        if (cookie) {
            const auth = JSON.parse(cookie);
            if (auth && auth.token) {
                setIsConnected(true);
            } else {
                setIsConnected(false);
            }
        } else {
            setIsConnected(false);
        }
    }, [cookie]);

    if(!isConnected) {
        navigate('/');
    }

    return isConnected ? <AdminLayout>{children}</AdminLayout> : <Navigate to="/" />;
};

export default PrivateRoute;
