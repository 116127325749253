import React from 'react';
import { useTranslation } from "react-i18next";

// Style
import '../../assets/copyright.scss';

const Copyright: React.FC = () => {

    const { t } = useTranslation();

    /**
     * Automatic copyright year increment
     * render if ${year} > 2022 :
     * 2022 - 2023 - `copyrightText`
    */ 
    const date = new Date();
    const year = date.getFullYear();

    return (
        <p className="copyright">{ year > 2022 ? `2022 - ${year}` : '2022' } {t('copyright.title')}</p>
    );
};

export default Copyright;
