import { createContext, ReactNode, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';

interface Title {
    back: string | null | NavigateFunction,
    title: string
};

interface Update {
    updateTitle: (
        back: string | null | NavigateFunction,
        title: string
    ) => void
};

const initialState: Title & Update = {
    back: null,
    title: '',
    updateTitle: () => null
};

const titleContext = createContext(initialState);

const TitleProvider = ({children}: {children?: ReactNode}) => {
    const [state, setState] = useState(initialState);

    const updateTitle = (
        back: string | null | NavigateFunction,
        title: string
    ) => {
        if(back !== state.back || title !== state.title) {
            setState({
                ...state,
                back: back,
                title: title
            });
        }
    };

    return (
        <titleContext.Provider value={{...state, updateTitle}}>
            {children}
        </titleContext.Provider>
    );
};

export {titleContext, TitleProvider};
