import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { SweetAlertProps } from 'react-bootstrap-sweetalert/dist/types';
import { CustomAlertProps } from './context/modalContext';

import '../../assets/modal.scss';

type ModalProps = {
    visibleAlert: SweetAlertProps & CustomAlertProps;
};

const Modal: React.FC<ModalProps> = ({ visibleAlert }): JSX.Element => {
    const customClass = visibleAlert?.customClass || 'modal';
    const confirmBtnCssClass = visibleAlert?.confirmBtnCssClass || 'btn btn-success';
    const cancelBtnCssClass = visibleAlert?.cancelBtnCssClass || 'btn btn-cancel';

    return (
        <SweetAlert
            customClass={customClass}
            confirmBtnCssClass={confirmBtnCssClass}
            cancelBtnCssClass={cancelBtnCssClass}
            {...visibleAlert}
        >
            {visibleAlert?.content && <div className="modal-message">{visibleAlert?.content}</div>}
        </SweetAlert>
    );
};

export default Modal;
